import React from 'react';
import { Link } from 'react-router-dom';
import './error.css';

function Error() {
	return (
		<div className='error-container'>
			<h1 className='error-title'>Något gick snett...</h1>
			<Link to='/' className='btn-link '>
				<button className='error-btn'>Tillbaka till startsidan</button>
			</Link>
		</div>
	);
}

export default Error;
