import React from 'react';
import { Youtube, Instagram, Mail } from 'react-feather';
import './footer.css';

function Footer() {
	return (
		<div className='footer-container'>
			<div className='icon-container'>
				<a
					href='https://www.instagram.com/ylvahanson/'
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
					<Instagram />
				</a>

				<a
					href='mailto:ylvahanson@gmail.com '
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
					<Mail />
				</a>

				<a
					href='https://www.youtube.com/user/ylvahansson/videos'
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
					<Youtube />
				</a>
			</div>
		</div>
	);
}

export default Footer;
