import React from 'react';
import './start.css';
import profile from '../assets/6.jpg';

function Start() {
	return (
		<div className='start-container'>
			<h1 className='hero-text'>Ylva Hanson</h1>
			<img className='start-img' src={profile} alt='profile of Ylva' />
		</div>
	);
}

export default Start;
