import React from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
function Navbar() {
	return (
		<header className='navbar'>
			<Link to='/' className='navbar-title'>
				Ylva Hanson
			</Link>
			<Link to='/about' className='navbar-item'>
				Om Ylva
			</Link>
			<Link to='/info' className='navbar-item'>
				CV
			</Link>
			<Link to='/stills' className='navbar-item'>
				Bilder
			</Link>
			<Link to='/reels' className='navbar-item'>
				Reels
			</Link>
			<Link to='/contact' className='navbar-item'>
				Kontakt
			</Link>
		</header>
	);
}

export default Navbar;
