import React from 'react';
import Button from '../components/Button';
import './contact.css';
import Footer from './Footer';
import profile from '../assets/6.jpg';

function Contact() {
	return (
		<div className='contact-container' id='contact'>
			<h1>Kontakt</h1>
			<div className='contact-wrapper'>
				<img className='contact-img' src={profile} alt='telephone' />
				<div className='contact-tex-container'>
					<h2>Kontakta mig</h2>
					<br />
					<h3>Jobba tillsammans, snacka lite eller ta en kaffe?</h3>
					<p>Kontakta mig så hör jag av mig så snart jag kan.</p>
					<Button />
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Contact;
