import React, { useState } from 'react';
import { X } from 'react-feather';
import './gallery.css';
import { imageData } from './data';

function Gallery() {
	const [model, setModel] = useState(false);
	const [tempImgSrc, setTempImgSrc] = useState('');

	const getImg = (imgSrc) => {
		setTempImgSrc(imgSrc);
		setModel(true);
	};

	return (
		<>
			<h1>Bilder</h1>
			<div className={model ? 'model open' : 'model'}>
				<img src={tempImgSrc} alt='profile' />
				<X onClick={() => setModel(false)} />
			</div>
			<div className='gallery' id='gallery'>
				{imageData.map((item, index) => {
					return (
						<div
							className='pics'
							key={index}
							onClick={() => getImg(item.imgSrc)}>
							<img className='gallery-img' src={item.imgSrc} alt='profile' />
						</div>
					);
				})}
			</div>
		</>
	);
}

export default Gallery;
