import React from 'react';
import Start from './Start';

function Home() {
	return (
		<div className='home-container'>
			<Start />
		</div>
	);
}

export default Home;
