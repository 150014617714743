export const infoData1 = [
	{
		id: 1,
		col1: '2020 - 2021',
		col2: 'STOCKHOLMS KONSTNÄRLIGA HÖGSKOLA',
		col3: 'Magisterexamen',
		col4: 'Internationell Scenkonst',
	},
	{
		id: 2,
		col1: '2010 - 2014',
		col2: 'THE KOGAN ACADEMY OF DRAMATIC ARTS, Kingston University, London ',
		col3: 'Kandidatexamen',
		col4: 'Directing (diploma)',
	},
	{
		id: 3,
		col1: '2005 - 2008',
		col2: 'STOCKHOLMS ESTETISKA GYMNASIUM ',
		col3: 'Gymnasium',
		col4: 'Teaterlinjen',
	},
	{
		id: 4,
		col1: '2001 - 2005',
		col2: 'BERGTORPSSKOLAN',
		col3: 'Högstadium',
		col4: 'Musik-Drama-Dans',
	},
];

export const infoData2 = [
	{
		id: 5,
		col1: '2019',
		col2: 'Shakespeare',
		col3: 'Josette Bushell-Mingo/Royal Shakespeare Company',
		col4: 'Stockholms Konstnärliga Högskola',
	},
	{
		id: 6,
		col1: '2019',
		col2: 'Musikteater i Samtiden',
		col3: 'Tina Glenvik',
		col4: 'Högskolan för Scen och Musik',
	},
	{
		id: 7,
		col1: '2018',
		col2: 'Musikdramatik för barn och unga',
		col3: 'Tina Glenvik',
		col4: 'Högskolan för Scen och Musik',
	},
	{
		id: 8,
		col1: '2017',
		col2: 'Scenspråkets musikalitet',
		col3: 'Joris Lacoste ',
		col4: 'Folkteatern/Kulturakademin ',
	},
	{
		id: 9,
		col1: '2017',
		col2: 'Butoh Dance Workshop ',
		col3: 'Tana Maneva',
		col4: 'Teater Giljotin',
	},
	{
		id: 10,
		col1: '2008',
		col2: 'Dramatiskt Skrivande',
		col3: 'Dennis Magnusson ',
		col4: 'Biskops Arnö',
	},
];

export const infoData3 = [
	{
		id: 11,
		col1: '2023',
		col2: 'Padeldrömmar',
		col3: 'TV',
		col4: 'V4/Helgeson Moll',
		col5: 'Kikki',
	},
	{
		id: 12,
		col1: '2022',
		col2: 'Monolog för Telefon',
		col3: 'Scen',
		col4: 'PotatoPotato',
		col5: 'Skådespelerska',
	},
	{
		id: 13,
		col1: '2022',
		col2: 'Jobbet, framför allt!',
		col3: 'Scen',
		col4: 'TeaterTillsammans/Kulturhuset Stadsteatern',
		col5: 'Kvinna',
	},
	{
		id: 14,
		col1: '2021',
		col2: 'SL - Framåt',
		col3: 'Internfilm',
		col4: 'Bond Street',
		col5: 'Pia',
	},
	{
		id: 15,
		col1: '2021',
		col2: 'Skolverket - Ledarskap i klassrummet',
		col3: 'Internfilm',
		col4: 'Bond Street',
		col5: 'Lärare Monique Lindberg',
	},
	{
		id: 16,
		col1: '2021',
		col2: 'AXA',
		col3: 'Reklamfilm',
		col4: 'Film de Liberté',
		col5: 'Kvinna',
	},
	{
		id: 17,
		col1: '2020',
		col2: 'Blocket',
		col3: 'Reklam',
		col4: 'Helgeson Moll',
		col5: 'Kvinna som intervjuas',
	},
	{
		id: 18,
		col1: '2020',
		col2: 'Panasonic',
		col3: 'Reklam',
		col4: 'Parapix',
		col5: 'Frun',
	},
	{
		id: 19,
		col1: '2019 - 2020',
		col2: 'Sagan om de två bönderna',
		col3: 'Scen',
		col4: 'Ylva & Isabell',
		col5: 'Den ena bonden',
	},
	{
		id: 20,
		col1: '2020',
		col2: 'Sverigelotten',
		col3: 'Reklam',
		col4: 'B-reel',
		col5: 'Flickvännen',
	},
];
