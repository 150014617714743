import React from 'react'
import './stills.css'
import Gallery from '../components/Image-Gallery/Gallery'

function Stills() {
  return (
    <div className='stills-container' id="stills">
      {/* <h1>Stills</h1> */}
        <Gallery />
      
    </div>
  )
}

export default Stills