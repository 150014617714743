import img1 from '../../assets/1.jpg';
import img2 from '../../assets/2.jpg';
import img3 from '../../assets/3.jpg';
import img4 from '../../assets/4.jpg';
import img5 from '../../assets/5.JPG';
import img6 from '../../assets/6.jpg';
import img7 from '../../assets/7.jpg';
import img8 from '../../assets/8.jpg';
import img9 from '../../assets/9.JPG';
import img10 from '../../assets/10.JPG';
import img11 from '../../assets/11.JPG';
import img12 from '../../assets/12.JPG';
import img13 from '../../assets/13.jpg';
import img14 from '../../assets/14.JPG';
import img15 from '../../assets/15.jpg';
import img16 from '../../assets/16.jpg';
import img17 from '../../assets/17.JPG';

export const imageData = [
	{
		id: 1,
		imgSrc: img1,
	},
	{
		id: 2,
		imgSrc: img2,
	},
	{
		id: 3,
		imgSrc: img3,
	},
	{
		id: 5,
		imgSrc: img5,
	},
	{
		id: 6,
		imgSrc: img6,
	},
	{
		id: 7,
		imgSrc: img7,
	},
	{
		id: 8,
		imgSrc: img8,
	},
	{
		id: 9,
		imgSrc: img9,
	},
	{
		id: 10,
		imgSrc: img10,
	},
	{
		id: 11,
		imgSrc: img11,
	},
	{
		id: 12,
		imgSrc: img12,
	},
	{
		id: 13,
		imgSrc: img13,
	},
	{
		id: 14,
		imgSrc: img14,
	},
	{
		id: 15,
		imgSrc: img15,
	},
	{
		id: 16,
		imgSrc: img16,
	},
	{
		id: 17,
		imgSrc: img17,
	},
	{
		id: 4,
		imgSrc: img4,
	},
];
