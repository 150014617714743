import React from 'react';
import './about.css';
import profile from '../assets/8.jpg';

function About() {
	return (
		<div className='about-container' id='about'>
			<h1>Om Ylva</h1>
			<div className='about-wrapper'>
				<img className='about-img' src={profile} alt='telephone' />

				<div className='about-tex-container'>
					<p className='about-text'>
						<span>Ylva Hanson</span> tog examen som skådespelare och regissör
						från The Kogan Academy of Dramatic Arts i London 2014. Under sex år
						var hon verksam i Storbritannien både inom film och teater, bland
						annat så deltog hon både som skådespelare och regissör i norra
						Londons största sagoföreställning för barn - “Once Upon a Time” och
						den nyskrivna föreställningen “Tarantella” på Omnibus Theatre i
						Clapham. Ylva spelade även ett flertal karaktärer i New Wave
						Comedy’s serie “Laugh Out Load Sketch Show”.
					</p>
					<p className='about-text'>
						<span>Efter</span> London så återvände Ylva till Sverige och
						Göteborg där hon arbetade med ett flertal produktioner på Gothenburg
						Fringe Festival, bland annat med föreställningen SIS+ERS som hon
						skapade tillsammans med sin syster Siri Hanson och som byggde på
						egna dagboksanteckningar och videos från barndommen. Hon medverkade
						även i ett flertal frigrupper, bland annat Teater Mareld.
					</p>
					<p className='about-text'>
						<span>2021 </span>avslutade Ylva sin magisterexamen i Internationell
						Scenkonst från Stockholms Konstnärliga Högskola. Hennes
						examensprojekt “A Doll’s World” undersöker vem Nora i “Ett Dockhem”
						skulle kunna vara idag i olika delar av världen. <br />
						<a href='https://www.diva-portal.org/smash/get/diva2:1582373/FULLTEXT01.pdf'>
							Länk till arbetet
						</a>
					</p>
					<p className='about-text'>
						<span>Ylva</span> sågs nyligen på Kulturhuset Stadsteatern i
						TeaterTillsammans produktion av “Jobbet, framför allt!” som mötes av
						fantastiska recensioner och fint publikmottagande. Snart får vi se
						henne i rollen som Kikki i “Padeldrömmar” på TV4.
					</p>
				</div>
			</div>
		</div>
	);
}

export default About;
