import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Start from './pages/Start';
import Info from './pages/Info';
import About from './pages/About';
import Stills from './pages/Stills';
import Contact from './pages/Contact';
import Reels from './pages/Reels';
import Footer from './pages/Footer';
import Error from './pages/Error';

import './App.css';

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route path='/' element={<Home />} exact />
				<Route path='/start' element={<Start />} />
				<Route path='/info' element={<Info />} />
				<Route path='/about' element={<About />} />
				<Route path='/stills' element={<Stills />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/reels' element={<Reels />} />
				<Route path='/contact' element={<Footer />} />
				<Route path='/404' element={<Error />} />
			</Routes>
		</Router>
	);
}

export default App;
