import React from 'react';
import './info.css';
import profile from '../assets/6.jpg';
import { infoData1, infoData2, infoData3 } from '../infoData';
import CV from '../assets/CV.pdf';
import { FileText } from 'react-feather';
function Info() {
	return (
		<div className='info-container' id='info'>
			<h1>CV</h1>
			<div className='info-header'>
				<img className='info-img' src={profile} alt='Profile of Ylva' />
				<div className='info-header-wrap'>
					<div className='info-header-block'>
						<h2>
							Email:{' '}
							<span>
								<a
									href='ylvahanson@gmail.com'
									target='_blank'
									rel='noopener noreferrer'>
									ylvahanson@gmail.com
								</a>
							</span>
						</h2>
						<h2>
							Telefon: <span>: (+46) 073 356 80 46</span>
						</h2>
					</div>
					<br />
					<div className='info-header-block'>
						<h2>
							Född: <span>1989</span>
						</h2>
						<h2>
							Ursprung: <span>Stockholm, Sverige</span>
						</h2>
						<h2>
							Språk: <span>Svenska & Engelska</span>
						</h2>
						<h2>
							Längd: <span>175 cm</span>
						</h2>
						<h2>
							Ögonfärg: <span>Grön</span>
						</h2>
						<h2>
							Hårfärg: <span>Mörkblond</span>
						</h2>
						<h2>
							Klädstorlek: <span>40/M</span>
						</h2>
						<h2>
							Röst: <span>Mezzo Soprano/alt</span>
						</h2>
						<h2>
							Färdigheter:{' '}
							<span>
								Musikteater, Shakespeare, Barnteater, Certifierad i Vijnana
								Yoga, Baskunskaper i Circus, Akrobatik och Stage Combat, Kajak,
								Snowboard, Jazzdans (Intermediate), Butoh Dance, mycket goda
								kunskaper i engelska.
							</span>
						</h2>
						<a
							className='info-link'
							href={CV}
							target='_blank'
							rel='noopener noreferrer'>
							Se hela mitt CV här <FileText />
						</a>
					</div>
				</div>
			</div>

			<div className='info-cv-container'>
				<div className='info-block'>
					<h3>Utbildning</h3>
					<table>
						<thead>
							<tr>
								<th>År</th>
								<th>Utbildning</th>
								<th>Nivå</th>
								<th>Inriktning</th>
							</tr>
						</thead>
						{infoData1.map((item, index) => {
							return (
								<tbody key={index}>
									<tr>
										<td>{item.col1}</td>
										<td>{item.col2}</td>
										<td>{item.col3}</td>
										<td>{item.col4}</td>
									</tr>
								</tbody>
							);
						})}
						<tr>
							<th className='h3'>Kurser</th>
						</tr>
						<thead>
							<tr>
								<th>År</th>
								<th>Kurs</th>
								<th>Kursledare</th>
								<th>Skola/teater</th>
							</tr>
						</thead>
						{infoData2.map((item, index) => {
							return (
								<tbody key={index}>
									<tr>
										<td>{item.col1}</td>
										<td>{item.col2}</td>
										<td>{item.col3}</td>
										<td>{item.col4}</td>
									</tr>
								</tbody>
							);
						})}

						<tr>
							<th className='h3'>Produktion</th>
						</tr>
						<thead>
							<tr>
								<th>År</th>
								<th>Produktion</th>
								<th>Branch</th>
								<th>Arbetsgivare</th>
								<th>Roll</th>
							</tr>
						</thead>
						{infoData3.map((item, index) => {
							return (
								<tbody key={index}>
									<tr>
										<td>{item.col1}</td>
										<td>{item.col2}</td>
										<td>{item.col3}</td>
										<td>{item.col4}</td>
										<td>{item.col5}</td>
									</tr>
								</tbody>
							);
						})}
					</table>
				</div>
			</div>
		</div>
	);
}

export default Info;
