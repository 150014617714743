import React from 'react';
import './button.css';

function Button() {
	return (
		<>
			<button id='btn'>
				<a
					className='btn-link'
					href='mailto:ylvahanson@gmail.com '
					target='_blank'
					rel='noopener noreferrer'>
					Kontakta Mig
				</a>
				<div id='circle'></div>
			</button>
		</>
	);
}

export default Button;
