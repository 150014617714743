import React from 'react';
import { Youtube } from 'react-feather';
import './reels.css';

function Reels() {
	return (
		<div className='reels-container'>
			<h1>Reels</h1>
			<div className='video-container'>
				<div className='video-wrapper'>
					<h2>Ylva Hanson Showreel </h2>
					<iframe
						width='460'
						height='315'
						src='https://www.youtube.com/embed/ns3Nm0-jAII'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen>
						Ylva Hanson Showreel
					</iframe>
				</div>
				<div className='video-wrapper'>
					<h2>Commercial reel 2021</h2>
					<iframe
						width='460'
						height='315'
						src='https://www.youtube.com/embed/wgeEUKlDiZM'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen>
						Commercial reel 2021
					</iframe>
				</div>
				<div className='video-wrapper'>
					<h2>A Doll's World</h2>
					<iframe
						width='460'
						height='315'
						src='https://www.youtube.com/embed/rLMHJ4g3HP4'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen>
						A Doll's World
					</iframe>
				</div>
				<div className='video-wrapper'>
					<h2>Short Film - LIFE ITSELF </h2>
					<iframe
						width='460'
						height='315'
						src='https://www.youtube.com/embed/RzqRmo7m_Ac'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen>
						Short Film - LIFE ITSELF
					</iframe>
				</div>
				<div className='video-wrapper'>
					<h2>
						Sagan om de två bönderna <br /> LIVE TRAILER{' '}
					</h2>
					<iframe
						width='460'
						height='315'
						src='https://www.youtube.com/embed/ZR0og2gOtco'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen>
						Sagan om de två bönderna - LIVE TRAILER
					</iframe>
				</div>
				<div className='video-wrapper'>
					<h2>
						"Dagen svalnar..." <br />
						av Edith Södergran{' '}
					</h2>
					<iframe
						width='460'
						height='315'
						src='https://www.youtube.com/embed/m2X_RqY1EAI'
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen>
						"Dagen svalnar..." av Edith Södergran
					</iframe>
				</div>
			</div>
			<div className='video-link'>
				<a
					href='https://www.youtube.com/user/ylvahansson/videos'
					target='_blank'
					title='YouTube video player'
					frameBorder='0'
					rel='noreferrer'>
					Gå till YouTube för att se fler reels
				</a>
				<Youtube />
			</div>
		</div>
	);
}

export default Reels;
